// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  position: fixed;
  z-index: 100;
  top: -100px; /* Start off-screen */
  left: 50%;
  transform: translateX(-50%);
  width: 400px; /* Increased width */
  height: 80px; /* Decreased height */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 10px;
  opacity: 0;
  animation: slideDown 0.25s forwards, slideUp 0.25s forwards 6s;
  cursor: pointer;
}

.notificationImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.notificationContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.notificationName {
  font-weight: bold;
  color: #363636;
  font-size: 16px;
  margin-bottom: 5px;
}

.notificationMessage {
  color: #666;
  font-size: 14px;
}

@keyframes slideDown {
  to {
    top: 1%; /* Adjust the position as needed */
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    top: 1%; /* Match the end position of slideDown */
    opacity: 1;
  }
  to {
    top: -20%; /* Slide back up a shorter distance */
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/Notification.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW,EAAE,qBAAqB;EAClC,SAAS;EACT,2BAA2B;EAC3B,YAAY,EAAE,oBAAoB;EAClC,YAAY,EAAE,qBAAqB;EACnC,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,UAAU;EACV,8DAA8D;EAC9D,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE;IACE,OAAO,EAAE,kCAAkC;IAC3C,UAAU;EACZ;AACF;;AAEA;EACE;IACE,OAAO,EAAE,wCAAwC;IACjD,UAAU;EACZ;EACA;IACE,SAAS,EAAE,qCAAqC;IAChD,UAAU;EACZ;AACF","sourcesContent":[".notification {\n  position: fixed;\n  z-index: 100;\n  top: -100px; /* Start off-screen */\n  left: 50%;\n  transform: translateX(-50%);\n  width: 400px; /* Increased width */\n  height: 80px; /* Decreased height */\n  background-color: white;\n  border: 1px solid #ccc;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  opacity: 0;\n  animation: slideDown 0.25s forwards, slideUp 0.25s forwards 6s;\n  cursor: pointer;\n}\n\n.notificationImage {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  margin-right: 10px;\n}\n\n.notificationContent {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.notificationName {\n  font-weight: bold;\n  color: #363636;\n  font-size: 16px;\n  margin-bottom: 5px;\n}\n\n.notificationMessage {\n  color: #666;\n  font-size: 14px;\n}\n\n@keyframes slideDown {\n  to {\n    top: 1%; /* Adjust the position as needed */\n    opacity: 1;\n  }\n}\n\n@keyframes slideUp {\n  from {\n    top: 1%; /* Match the end position of slideDown */\n    opacity: 1;\n  }\n  to {\n    top: -20%; /* Slide back up a shorter distance */\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
